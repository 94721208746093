<template>
<!-- Loader -->
  <div v-if="isLoading" class="flex w-full items-center justify-center max-h-sm">
    <LoadingData :message="'Logging in..'" />
  </div>
<!-- Error from Login API -->
  <div v-if="responseError" class="error">{{ responseError }}</div>
<!-- Login Form -->
  <form @submit.prevent="onSubmit" novalidate>
    <div class="flex flex-col w-full flex-nowrap space-y-4 px-4 pt-3">
      <div class="w-full flex flex-col space-y-1">
        <!-- Email Addresss Input -->
        <div class="flex flex-row w-full items-center space-x-1 text-sm">
          <AtSymbolIcon class="h-7 w-7" :class="error.email ? 'text-red-700': null"/>
          <input type="email" name="email" placeholder="Email" class="flex-grow flex h-6 px-1 border-b-2 border-neutral-700 dark:border-neutral-300 focus:ring-0 rounded-none bg-neutral-50 dark:bg-neutral-900" @input="error.email = ''"
            :class="error.email ? 'border-red-700' : null"
            v-model.trim="email"
          />
        </div>
        <div v-if="error.email" class="error w-full text-sm px-8">
          {{ error.email }}
        </div>
      </div>
      <!-- Password Input -->
      <div class="w-full flex flex-col space-y-1">
        <div class="flex flex-row w-full items-center space-x-1 text-sm">
          <LockClosedIcon class="h-7 w-7" :class="error.password ? 'text-red-700': null" />
          <input type="password" name="password" placeholder="Password" class="bg-opacity-0 flex-grow flex h-6 px-1 border-b-2 border-neutral-700 dark:border-neutral-300 focus:ring-0 rounded-none" @input="error.password = ''"
           :class=" error.password ? 'border-red-700' : null"
            v-model.trim="password"
            autocomplete="off"
          />
        </div>
        <div v-if="error.password" class="error w-full px-8 text-sm">
          {{ error.password }}
        </div>
      </div>
      <button class="bn-solid p-1 self-center w-36" type="submit" :disabled="isLoading">
          Login
      </button>
    </div>
  </form>
</template>

// <script>
import router from "../router/index";
import { ref, inject, onMounted } from "vue";
import axios from "axios";

import {AtSymbolIcon,LockClosedIcon} from "@heroicons/vue/outline"

let isLoading = ref(false);
let responseError = ref("");
const email = ref("");
const password = ref("");
const error = ref({
  email: "",
  password: "",
});
const reLogin = ref(false);

export default {
  name: "LoginDialog",
  components: {
    LockClosedIcon,
    AtSymbolIcon
  },
  setup() {
    const global = inject("global");
    const { login, authenticated } = global;

    onMounted(()=>{
      localStorage.getItem('lastLogin') ? email.value = localStorage.getItem('lastLogin') : null
    })

    // Validate email and password format on submit
    const onSubmit = async () => {
      error.value = {
        email: "",
        password: "",
      }
      !email.value || !email.value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
        ? (error.value.email = "Please enter a valid email")
        : (error.value.email = "");

      !password.value
        ? (error.value.password = "Please enter a password")
        : (error.value.password = "");

      !error.value.email && !error.value.password ? submitLogin() : "";
    };

    // Login with provided credentials
    const submitLogin = async () => {
      responseError.value = ''
      isLoading.value = true;
      let values = { email: email.value, password: password.value };
      password.value = "";

      if (authenticated.value.loggedIn) {
        reLogin.value = true;
      }
      await axios
        .post("/api/v1/auth/login", values)
        .then((response) => {
          if (response.status == 200) {
            if (!response.data?.token) {
              responseError.value = "Problem with token";
            } else if (
              response.data?.email &&
              response.data?.role &&
              response.data?.name &&
              response.data?.token &&
              response.data?.organization &&
              response.data?._id
            ) {
              login(
                response.data?.email,
                response.data?.role,
                response.data?.name,
                response.data?.token,
                response.data?.organization,
                response.data?.timesheet || false,
                response.data?.onSchedule || false,
                response.data?._id,
                response.data?.fourDayWeek || false,
                response.data?.darkModeState,
                response.data?.scrollBarState
              );
              localStorage.setItem("other-tab-login", "");
              //localStorage.clear();
              localStorage.setItem("lastLogin",response.data.email)
              responseError.value = "";
              const redirectTarget =
                sessionStorage.getItem("redirectTarget") || "/";
              if (sessionStorage.getItem("redirectTarget")) {
                sessionStorage.removeItem("redirectTarget");
              }
              if (!reLogin.value) {
                router.push(redirectTarget);
              }
              return;
            } else {
              responseError.value = "Problem with login";
            }
          }
        })
        .catch((err) => {
          responseError.value = err.response?.data?.error || err.message;
        })
        .finally(()=>{
          isLoading.value = false
        })
    };

    return {
      // user input
      email,
      password,
      // functions
      onSubmit,
      submitLogin,
      isLoading,
      responseError,
      login,
      error,
    };
  },
};
</script>
